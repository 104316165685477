/**
 * ============
 * MENU RULES
 * =========
 * Menu userTypePermission rules:
 *
 * ["*"] = Open for all logged user
 * ["-admin"] = Open for all logged user, EXCEPT admin
 * ["-admin", "student", "teacher"] = THIS RULE IS INVALID BUT IT WILL STILL HANDLED IT
 * ["student", "teacher"] = THIS RULE IS BETTER THAN PREV RULE. Open for student and teacher only
 *
 */

import { translate } from "../helpers/translate";
import UniversalMenuConfig from "./UniversalMenuConfig";

const AppMenuConfig: object = [
  {
    pages: [
      ...UniversalMenuConfig[0].pages,
      {
        heading: translate("aside.menu.transaction"),
        route: "/app/invocation",
        svgIcon: require("@/assets/media/icons/duotone/Interface/Monitor.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        sub: [
          {
            heading: translate("aside.menu.invocation"),
            route: "/app/invocation",
            svgIcon: require("@/assets/media/icons/duotone/Code/Time-schedule.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "invocation-list"
          },
          {
            heading: translate("aside.menu.bill"),
            route: "/app/bill",
            svgIcon: require("@/assets/media/icons/duotone/Shopping/Bitcoin.svg"),
            userTypePermission: ["*"]
          },
          {
            heading: translate("aside.menu.payment"),
            route: "/app/payment",
            svgIcon: require("@/assets/media/icons/duotone/Shopping/Rouble.svg"),
            userTypePermission: ["*"]
          },
          {
            heading: translate("aside.menu.webhook"),
            route: "/app/webhook",
            svgIcon: require("@/assets/media/icons/duotone/Map/Position.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "webhook-list"
          }
        ]
      },
      
      {
        heading: translate("aside.menu.management"),
        route: "/app/management",
        svgIcon: require("@/assets/media/icons/duotone/Communication/Shield-user.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        accessPermissionName: ["client-list", "institution-list", "account-list", "user-list"],
        sub: [
          {
            heading: translate("aside.menu.management.client"),
            route: "/app/management/client",
            svgIcon: require("@/assets/media/icons/duotone/General/Smile.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "client-list",
          },
          {
            heading: translate("aside.menu.management.institution"),
            route: "/app/management/institution",
            svgIcon: require("@/assets/media/icons/duotone/Home/Building.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "institution-list",
          },
          {
            heading: translate("aside.menu.management.user"),
            route: "/app/management/user",
            svgIcon: require("@/assets/media/icons/duotone/Communication/Contact1.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "user-list",
          },
          {
            heading: translate("aside.menu.management.account"),
            route: "/app/management/account",
            svgIcon: require("@/assets/media/icons/duotone/Communication/Shield-user.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "account-list",
          },
          {
            heading: translate("aside.menu.management.gateway"),
            route: "/app/management/gateway",
            svgIcon: require("@/assets/media/icons/duotone/Communication/Outgoing-box.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "gateway-list",
          }
        ]
      },
      {
        heading: translate("aside.menu.reconcile"),
        route: "/app/reconcile",
        svgIcon: require("@/assets/media/icons/duotone/Code/Time-schedule.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        accessPermissionName: ["reconcile-file", "reconcile-journal-entry-list"],
        sub: [
          {
            heading: translate("aside.menu.reconcile.channel"),
            route: "/app/reconcile",
            svgIcon: require("@/assets/media/icons/duotone/Files/File-done.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "reconcile-file",
          },
          {
            heading: translate("aside.menu.reconcile.journal.file"),
            route: "/app/journal-entry/file",
            svgIcon: require("@/assets/media/icons/duotone/Files/Selected-file.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "reconcile-journal-entry-files"
          },
          {
            heading: translate("aside.menu.reconcile.journal"),
            route: "/app/journal-entry",
            svgIcon: require("@/assets/media/icons/duotone/Files/Protected-file.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "reconcile-journal-entry-list"
          },
        ]
      },
      {
        type: "separator",
        userTypePermission: ["*"]
      },
      {
        heading: translate("aside.menu.transfer"),
        route: "/app/transfer/history",
        svgIcon: require("@/assets/media/icons/duotone/Code/Thunder-circle.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        accessPermissionName: ["transfer-history-list"],
        sub: [
          {
            heading: translate("aside.menu.transfer.history"),
            route: "/app/transfer/history",
            svgIcon: require("@/assets/media/icons/duotone/Text/Text-width.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "transfer-history-list"
          },
        ]
      },
      {
        type: "separator",
        userTypePermission: ["*"],
        accessPermissionName: "transfer-history-list"
      },
      {
        heading: translate("aside.menu.report"),
        route: "/app/report/channel",
        svgIcon: require("@/assets/media/icons/duotone/Files/Protected-file.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        accessPermissionName: "report-by-channel",
        sub: [
          {
            heading: translate("aside.menu.report.channel"),
            route: "/app/report/channel",
            svgIcon: require("@/assets/media/icons/duotone/Code/Git2.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "report-by-channel"
          },
          {
            heading: translate("aside.menu.report.invocation"),
            route: "/app/report/invocation",
            svgIcon: require("@/assets/media/icons/duotone/Code/Git2.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "report-by-invocation"
          },
        ]
      },
      {
        type: "separator",
        userTypePermission: ["*"],
        accessPermissionName: "report-by-channel",
      },
      {
        heading: translate("aside.menu.setting"),
        route: "/app/channel",
        svgIcon: require("@/assets/media/icons/duotone/Code/Settings4.svg"),
        fontIcon: "bi-app-indicator",
        userTypePermission: ["-admin"],
        accessPermissionName: ["channel-list"],
        sub: [
          {
            heading: translate("aside.menu.setting.channel"),
            route: "/app/channel",
            svgIcon: require("@/assets/media/icons/duotone/Code/CMD.svg"),
            userTypePermission: ["*"],
            accessPermissionName: "channel-list"
          },
        ]
      },
      {
        type: "separator",
        userTypePermission: ["*"],
        accessPermissionName: "channel-list",
      },
      
    ]
  }
];
export default AppMenuConfig;
