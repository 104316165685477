
import { reactive, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/translate";
import {
  useQuery,
} from "@/core/helpers/common-helper";
import {
  showErrorPopup,
  showSuccessPopup,
  inputNumbersOnly
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { numberFormat } from "@/core/helpers/currency-helper";
import { useRouter } from "vue-router";
import useTransferType from "@/core/services/compositions/enum/useTransferType";

interface Overbooking {
  source_account: string;
  source_account_holder: string;
  destination_account: string;
  destination_account_holder: string;
  amount: number;
  description: string;
  summary_id: string;
  transfer_type: string;
  va_billing_info: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const dialogVisible = ref(false);
    const dialogOTPVisible = ref(false);
    const isValidatingSource = ref(false);
    const isValidatingDestination = ref(false);
    const { query } = useQuery();
    const destinationAccount = ref();
    const sourceAccount = ref();
    const descriptionDestinationAccount = ref();
    const descriptionSourceAccount = ref();
    const router = useRouter();
    const inquiryInProcess = ref(false);
    const otp1 = ref('');
    const otp2 = ref('');
    const otp3 = ref('');
    const otp4 = ref('');
    
    const { transferType, isValidating: isValidatingType } = useTransferType();

    const overbooking = reactive<Overbooking>({
      source_account: '',
      source_account_holder: '',
      destination_account: '',
      destination_account_holder: '',
      amount: parseInt(query.value.amount),
      summary_id: query.value.summary_id,
      description: '',
      transfer_type: '',
      va_billing_info: '',
    });

    const showDialog = () => {
      if (overbooking.source_account == null || overbooking.source_account == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Source Account Number] cannot empty',
          message: '[Source Account Number] cannot empty',
        })

        return;
      }

      if (overbooking.destination_account == null) {
        showErrorPopup({
          title: 'Error',
          text: '[Destination Account Number] cannot empty',
          message: '[Destination Account Number] cannot empty',
        })

        return;
      }

      if (overbooking.amount == null) {
        showErrorPopup({
          title: 'Error',
          text: '[Amount] cannot empty',
          message: '[Amount] cannot empty',
        })

        return;
      }

      dialogVisible.value = true;

      if (overbooking.transfer_type == 'va') {
        inquiryInProcess.value = true;

        const payload = {
          account_number: overbooking.destination_account,
        };

        ApiService.setHeader();
        ApiService.post(
          `app/finance/account/inquiry`,
          payload as any
        )
          .then((response) => {
            overbooking.destination_account_holder = response.data.data.acctName;
            overbooking.va_billing_info = response.data.data.vaBillingInfo;
          })
          .catch(({ response }) => {

            if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
              if (response.data.data.length > 0) {
                for (const key in response.data.data) {
                  showErrorPopup({
                    title: response.data.rc,
                    text: response.data.data[key],
                    message: response.data.data[key],
                  })
                }
              } else {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.message,
                  message: response.data.message,
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }

            dialogVisible.value = true;
          })
          .finally(() => {
            submitButton.value ? (submitButton.value.disabled = false) : null;
            submitButton.value?.removeAttribute("data-kt-indicator");

            inquiryInProcess.value = false;
          });
      }
    }

    const getSourceAccount = () => {
      isValidatingSource.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/channel-pool-account`,
        "",
        {
          params: {
            identification_id: query.value.channel,
          }
        }
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            ApiService.getWithoutSlug(
              `app/enum/account`,
              "",
              {
                params: {
                  account_numbers: response.data.data,
                  type: 'internal'
                }
              }
            )
              .then((resp) => {
                sourceAccount.value = resp.data.data;
              })
              .finally(() => {
                isValidatingSource.value = false;
              });
          }
        })
        .catch(() => {
          isValidatingSource.value = false;
        });
    };

    const getDestinationAccount = () => {
      isValidatingDestination.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/account`,
        "",
        {
          params: {
            type: query.value.type,
            identification_id: query.value.holder
          }
        }
      )
        .then((resp) => {
          destinationAccount.value = resp.data.data;
        })
        .finally(() => {
          isValidatingDestination.value = false;
        });
    };

    getSourceAccount();
    getDestinationAccount();

    const checkOtp = () => {
      dialogVisible.value = false;
      dialogOTPVisible.value = true;
    }

    const submitOverbooking = () => {
      dialogVisible.value = false;
      dialogOTPVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        source_account: overbooking.source_account,
        destination_account: overbooking.destination_account,
        amount: overbooking.amount,
        description: overbooking.description,
        transfer_type: overbooking.transfer_type,
        va_billing_info: overbooking.va_billing_info,
        to_acct_name: overbooking.destination_account_holder,
        summary_id: overbooking.summary_id,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/overbooking`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Overbooking',
            text: 'Success'
          });

          overbooking.source_account = '';
          overbooking.destination_account = '';
          overbooking.description = '';
          overbooking.transfer_type = '';
          overbooking.va_billing_info = '';

          setTimeout(() => {
            router.push({ name: "app.transfer.history" });
          }, 1700)
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const gotoInquiry = () => {
      router.push({ name: "app.finance.account.inquiry" });
    };

    const changeSourceAccount = (val) => {
      const result = sourceAccount.value.filter(data => data.account_number == val);

      if (result.length > 0) {
        descriptionSourceAccount.value = result[0].description;
        overbooking.source_account_holder = result[0].account_holder;
      }
    }

    const changeDestinationAccount = (val) => {
      const result = destinationAccount.value.filter(data => data.account_number == val);

      if (result.length > 0) {
        descriptionDestinationAccount.value = result[0].description
        overbooking.destination_account_holder = result[0].account_holder
      }
    }

    const terbilang = (a) => {
      const bilangan = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan', 'Sepuluh', 'Sebelas'];
      let kalimat = '';

      // 1 - 11
      if (a < 12) {
        kalimat = bilangan[a];
      }
      // 12 - 19
      else if (a < 20) {
        kalimat = bilangan[a - 10] + ' Belas';
      }
      // 20 - 99
      else if (a < 100) {
        const utama = a / 10;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10;
        kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang];
      }
      // 100 - 199
      else if (a < 200) {
        kalimat = 'Seratus ' + terbilang(a - 100);
      }
      // 200 - 999
      else if (a < 1000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 100;
        kalimat = bilangan[depan] + ' Ratus ' + terbilang(belakang);
      }
      // 1,000 - 1,999
      else if (a < 2000) {
        kalimat = 'Seribu ' + terbilang(a - 1000);
      }
      // 2,000 - 9,999
      else if (a < 10000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000;
        kalimat = bilangan[depan] + ' Ribu ' + terbilang(belakang);
      }
      // 10,000 - 99,999
      else if (a < 100000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 100,000 - 999,999
      else if (a < 1000000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 1,000,000 - 	99,999,999
      else if (a < 100000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 1000000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 10000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 100000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 1000000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 10000000000000) {
        const utama = a / 10000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }
      else if (a < 100000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 1000000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 10000000000000000) {
        const utama = a / 1000000000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000000000;
        kalimat = terbilang(depan) + ' Kuadriliun ' + terbilang(belakang);
      }

      const pisah = kalimat.split(' ');
      const full = [''];
      for (let i = 0; i < pisah.length; i++) {
        if (pisah[i] != "") { full.push(pisah[i]); }
      }
      return full.join(' ');
    }

    return {
      translate,
      overbooking,
      dialogVisible,
      dialogOTPVisible,
      submitButton,
      showDialog,
      submitOverbooking,
      checkOtp,
      inputNumbersOnly,
      numberFormat,
      destinationAccount,
      sourceAccount,
      terbilang,
      gotoInquiry,
      isValidatingDestination,
      isValidatingSource,
      descriptionDestinationAccount,
      descriptionSourceAccount,
      changeSourceAccount,
      changeDestinationAccount,
      transferType,
      inquiryInProcess,
      isValidatingType,
      otp1,
      otp2,
      otp3,
      otp4
    };
  }
});
